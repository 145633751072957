import {combineReducers}  from  'redux';
import Funeral from './Funeral'
import Planning from './Planning'
import Upcoming from './Upcoming'
import CeremonyRoom from './CeremonyRoom'

const reducer = combineReducers({
  Funeral,
  Planning,
  Upcoming,
  CeremonyRoom
})

export default reducer;