import React, {Suspense} from 'react'
import { HashRouter } from 'react-router-dom'
import { routes } from './routes/routes'

function App() {
  const baseUrl = '/'
  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading...</div>}>
        <HashRouter children={routes} basename={baseUrl} />
      </Suspense>
    </React.Fragment>
  );
}

export default App;
